import React from 'react';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/client';
import { useProfileProvider } from '../../store/ProfileProvider';
import SignInUserMir3d from '../SignInOptionsDesktopComponent/SignInUserMir3d';
import SignInUserManufacturer from '../SignInOptionsDesktopComponent/SignInUserManufacturer';

import styles from './styles/NavProfile.module.css';
import Link from 'next/link';

const NavProfileItems = ({
    userMenuRef,
    isMenuOpen,
    onDisplayProfileSwitcher
}: $TSFixMe) => {
    // @ts-expect-error TS(2339): Property 'store' does not exist on type 'unknown'.
    const { store } = useProfileProvider();
    const router = useRouter(); //accede al objeto router de la app.
    const [session, loading] = useSession();
    let firstMenuList = null;

    switch (store.profile) {
        case 'MIR3D':
            // @ts-expect-error TS(2322): Type '{ router: NextRouter; }' is not assignable t... Remove this comment to see the full error message
            firstMenuList = <SignInUserMir3d router={router} />
            break;
        case 'FABRICANTE':
            // @ts-expect-error TS(2322): Type '{ router: NextRouter; }' is not assignable t... Remove this comment to see the full error message
            firstMenuList = <SignInUserManufacturer router={router} />
            break;
        default:
            firstMenuList = null;
            break;
    }

    const handleOnSignOut = () => {
        signOut();
        localStorage.removeItem('profile');
    };

    return (
        <ul ref={userMenuRef} className={isMenuOpen ? styles.menu_container : styles.menu_container_noActive}>
            {firstMenuList}
            {
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                store.profile && session.user.registeredProfile && !loading &&
                <li className={styles.menu_item}>
                    <button onClick={onDisplayProfileSwitcher}>Cambiar perfil</button>
                </li>
            }
            <li className={styles.menu_item}>
                <Link href='/contact'><a target='_blank'>Contacto</a></Link>
            </li>
            {session ?
                <li className={styles.menu_item}>
                    <button onClick={handleOnSignOut}>Cerrar sesión</button>
                </li>
                :
                <div>
                    <li className={styles.menu_item} onClick={() => router.push('/sign_in')}>
                        <Link href='/sign_in'><a>INICIAR SESIÓN</a></Link>
                    </li>
                    <li className={styles.menu_item} onClick={() => router.push('/sign_up')}>
                        <Link href='/sign_up'><a>REGISTRO</a></Link>
                    </li>
                </div>
            }
        </ul>
    )
}

export default NavProfileItems;