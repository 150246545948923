import { useMutation } from "@apollo/client";
import { Dialog, Typography } from "@mui/material";
import { signIn, useSession } from "next-auth/client";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { UPDATE_USER_PROFILES } from "../../apollo/api/user/mutations";
import { getUserAuthToken } from "../../helpers/getUserAuthToken";
import LoadingFeedback from "../LoadingComponent/LoadingFeedback";
import ProfilesDesktop from "../NewProfileSectionComponent/ProfilesDesktop";
import Snackbar from "../SnackbarComponent/Snackbar";
import styles from "./styles/SwitchProfile.module.css";
import DialogCard from "../common/DialogCard";

const SwitchProfile = ({
  onClose
}: $TSFixMe) => {
  const [session, loading] = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const [updateUserProfiles, { loading: loadingUpdateProfiles }] = useMutation(
    UPDATE_USER_PROFILES,
    {
      onCompleted: async (data) => {
        enqueueSnackbar("", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          // eslint-disable-next-line react/display-name
          content: () => {
            return (
              <Snackbar
                 
                variant={"success"}
                message={"El nuevo perfil fue activado correctamente"}
              />
            );
          },
        });

        await signIn("update-session", { //Updating the user session with the active profiles
          redirect: false,
          token: await getUserAuthToken()
        });

        localStorage.setItem('profile', data.updateUserProfiles); //Set the new profile in the localstorage

        router.reload();
      },
      onError: (error) => {
        enqueueSnackbar("", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          // eslint-disable-next-line react/display-name
          content: () => {
             
            return <Snackbar variant={"error"} message={error.message} />;
          },
        });
      },
    }
  );

  const getIsoProfile = (profile: $TSFixMe) => {
    let isoProfile;
    switch (profile) {
      case "MIR3D":
        isoProfile = "/seleccionar-mir3d.svg";
        break;
      case "CREADOR":
        isoProfile = "/seleccionar-creador.svg";
        break;
      case "FABRICANTE":
        isoProfile = "/seleccionar-fabricar.svg";
        break;
      default:
        isoProfile = "/seleccionar-mir3d.svg";
        break;
    }

    return isoProfile;
  };

  const filterProfiles = () => {
    let availableProfiles = ["MIR3D", "FABRICANTE"];
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    for (const profile of session.user.profiles) {
      availableProfiles = availableProfiles.filter((p) => p !== profile);
    }

    return availableProfiles;
  };

  const handleOnClickNewProfile = async (profile: $TSFixMe) => {
    await updateUserProfiles({
      variables: {
        data: {
          profile: profile,
        },
        where: {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          id: session.user.userId,
        },
      },
    });
  };

  const handleOnChangeProfile = (profile: $TSFixMe) => {
    if (profile !== localStorage.getItem("profile")) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      if (session.user.profiles.includes(profile)) {
        localStorage.setItem("profile", profile);
        router.reload();
      }
    }
    else {
      onClose();
    }
  };

  return (
    <DialogCard
      hasCloseBtn={true}
      onClose={onClose}
    >
      <Dialog
        PaperProps={{ style: { overflowY: 'unset' } }}
        BackdropProps={{ style: { background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)' } }}
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'JSXElemen... Remove this comment to see the full error message
        PaperComponent={'div'}
        open={loadingUpdateProfiles}
      >
        <LoadingFeedback />
        <Typography marginTop={2} fontFamily={'var(--font-family)'} letterSpacing={1} color={'var(--gray-strong)'}>Configurando tu nuevo perfil...</Typography>
      </Dialog>
      <div className={styles.switch_profile}>
        {loading ? (
          <LoadingFeedback />
        ) : (
          <div className={styles.switch_profile_content}>
            <div className={styles.switch_profile_section}>
              <h4>Perfil principal</h4>
              <p>El perfil seleccionado se muestra al iniciar sesión</p>
              {
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                <div className={styles.profile_item} onClick={() => handleOnChangeProfile(session.user.mainProfile)}>
                  <Image
                    // @ts-expect-error TS(2531): Object is possibly 'null'.
                    src={getIsoProfile(session.user.mainProfile)}
                    alt="isotipo r3d logo"
                    width={60}
                    height={60}
                    priority={true}
                  />
                  {
                    // @ts-expect-error TS(2531): Object is possibly 'null'.
                    <span className="text-base font-bold text-black-strong">{session.user.mainProfile}</span>
                  }
                </div>
              }
            </div>
            {
              // @ts-expect-error TS(2531): Object is possibly 'null'.
              session.user.profiles.filter((profile: $TSFixMe) => profile !== 'CREADOR').length >= 2 && (
                <div className={styles.switch_profile_section}>
                  <h4>Perfiles secundarios</h4>
                  <p>Puedes cambiar a uno de los perfiles secundarios</p>
                  <div className={styles.switch_profile_secondary_profiles}>
                    {
                      // @ts-expect-error TS(2531): Object is possibly 'null'.
                      session.user.profiles
                        // @ts-expect-error TS(2531): Object is possibly 'null'.
                        .filter((profile: $TSFixMe) => profile !== session.user.mainProfile && profile !== 'CREADOR')
                        .map((profile: $TSFixMe, index: $TSFixMe) => {
                          return (
                            <div
                              key={index}
                              className={styles.profile_item}
                              onClick={() => handleOnChangeProfile(profile)}
                            >
                              <Image
                                src={getIsoProfile(profile)}
                                alt="isotipo r3d logo"
                                width={60}
                                height={60}
                                priority={true}
                              />
                              <span className="text-base font-bold text-black-strong">{profile}</span>
                            </div>
                          );
                        })}
                  </div>
                </div>
              )}
            <div className={styles.switch_profile_section}>
              {filterProfiles().length >= 1 && (
                <Fragment>
                  <h4>Seleccionar un nuevo perfil</h4>
                  <p>
                    Tendrás acceso a funcionalidades relacionadas con tu
                    selección
                  </p>
                  <div className={styles.switch_profile_secondary_profiles}>
                    <ProfilesDesktop
                      handleOnClickProfile={handleOnClickNewProfile}
                      availableProfiles={filterProfiles()}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </DialogCard>
  );
};

export default SwitchProfile;
