import { CircularProgress } from '@mui/material';
import React, { Fragment, ReactElement } from 'react';
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from '@/lib/utils';

const buttonVariants = cva(
  "inline-flex items-center gap-2 tracking-widest font-bold justify-center whitespace-nowrap text-xs ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-orange-main border-2 border-white-main text-white-main rounded-full",
        outline: "bg-white-main border-2 border-orange-main text-orange-main rounded-full",
        secondary: "bg-gray-ligth border-2 border-white-main text-gray-strong rounded-full",
        link: "text-orange-main underline-offset-4 underline",
        ghost: "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50 rounded-md"
      },
      size: {
        default: "h-8 px-4 py-1",
        sm: "h-8 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-8 w-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
  name?: string,
  startIcon?: ReactElement
  endIcon?: ReactElement
  isLoadingRequest?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, name, endIcon, startIcon, isLoadingRequest, children, ...props }, ref) => {
    const Comp = "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {
          isLoadingRequest ?
            <CircularProgress size={14} style={{ color: 'var(--white-main)' }} /> :
            <Fragment>
              {startIcon && startIcon}
              {name ? name : children}
              {endIcon && endIcon}
            </Fragment>
        }
      </Comp>
    )
  }
)

Button.displayName = "Button"

export default Button;


