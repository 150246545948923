import React, { useRef, useState } from 'react';
import Image from 'next/image';
import useHandleShowMenu from '../SignInOptionsDesktopComponent/hooks/useHandleShowMenu';
import SwitchProfile from '../SwitchProfileComponent/SwitchProfile';
import useHandleSwitchIconProfile from '../SignInOptionsDesktopComponent/hooks/useHandleSwitchIconProfile';
import NavProfileItems from './NavProfileItems';

import styles from './styles/NavProfile.module.css';

const NavProfileDesktop = ({
    username
}: $TSFixMe) => {
    const userContainer = useRef(null);
    const userMenu = useRef(null);
    const { iconProfile } = useHandleSwitchIconProfile();
    const [isMenuOpen, handleOpenMenu] = useHandleShowMenu(userContainer.current, userMenu.current);
    const [displayProfileSwitcher, setDisplayProfileSwitcher] = useState(false);

    return (
        <div ref={userContainer} className={styles.user_info_container}>
            {
                displayProfileSwitcher &&
                <SwitchProfile onClose={() => setDisplayProfileSwitcher(false)} />
            }
            {
                // @ts-expect-error TS(2349): This expression is not callable.
                <div className={styles.user_info} onClick={() => { handleOpenMenu(userMenu.current) }}>
                    <p id={'username'} className={styles.username}>{username}</p>
                    <Image className={styles.profile_image} src={iconProfile} alt='Icono de perfil' width={36} height={36} />
                </div>
            }
            <NavProfileItems
                userMenuRef={userMenu}
                isMenuOpen={isMenuOpen}
                onDisplayProfileSwitcher={() => setDisplayProfileSwitcher(true)}
            />
        </div>
    )
}

export default NavProfileDesktop;