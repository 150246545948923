import { useEffect, useState } from "react";
import { useProfileProvider } from "../../../store/ProfileProvider";

const useHandleSwitchIconProfile = () => {
    const [iconProfile, setIconProfile] = useState('/account');
    // @ts-expect-error TS(2339): Property 'store' does not exist on type 'unknown'.
    const { store } = useProfileProvider();

    useEffect(() => {
        switch (store.profile) {
            case 'MIR3D':
                setIconProfile('/seleccionar-mir3d.svg');
                break;
            case 'CREADOR':
                setIconProfile('/seleccionar-creador.svg');
                break;
            case 'FABRICANTE':
                setIconProfile('/seleccionar-fabricar.svg');
                break;
            default:
                setIconProfile('/placeholder-profile-icon.png');
                break;
        }
    }, [store?.profile]);

    return {
        iconProfile
    }
}

export default useHandleSwitchIconProfile;