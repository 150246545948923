import { Fragment } from "react";
import Link from "next/link";
import styles from '../NavbarComponent/styles/NavProfile.module.css'

const SignInUserMir3d = () => {
    return (
        <Fragment>
            <li className={styles.menu_item}>
                <Link href='/my_profile'><a>Mi cuenta</a></Link>
            </li>
        </Fragment>
    )
}

export default SignInUserMir3d;