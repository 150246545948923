import gsap from "gsap";
import { useCallback, useEffect, useState } from "react";

const useHandleShowMenu = (ref: $TSFixMe, refMenu: $TSFixMe) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false); //Guarda el estado del menu desplegado, si esta desplegado o no.

    /*Desplega la lista de opciones o las culta para un menu cualquiera, haciendo uso de ref para hacer referencia a un menu. */
    const handleOpenMenu = async (ref: $TSFixMe) => {
        if (isMenuOpen) {
            //Se hace uso de gsap para agregar una ligera animacion al desplegar el menu, ref: https://greensock.com/docs/v3/GSAP.
            await gsap.to(ref,{
                duration: 0.3,
                opacity: 0,
            });
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
            gsap.to(ref,{
                duration: 0.3,
                opacity: 1,
            })
        }
    }

    //Cuando se escucha el evento mousedown en la pagina, esta funcion cierra el menu desplegado.
    const handleOnClickOutSideMenu = useCallback( async (e: $TSFixMe) => {
        if (ref && !ref.contains(e.target)) {
            await gsap.to(refMenu,{
                duration: 0.3,
                opacity: 0,
            });
            refMenu.current?.scrollTo({ top: 0 });
            setIsMenuOpen(false)
        }
    },[refMenu, ref]);

    //Escuchando el evento mousedown...
    useEffect(() => {
        document.addEventListener('mousedown', handleOnClickOutSideMenu);

        return () => {
            document.removeEventListener('mousedown', handleOnClickOutSideMenu);
            gsap.killTweensOf(ref);
            gsap.killTweensOf(refMenu);
        }

    },[handleOnClickOutSideMenu, ref, refMenu]);

    return [isMenuOpen, handleOpenMenu]
}

export default useHandleShowMenu;