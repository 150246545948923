import React from "react";
import { Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Button from "../../ButtonsComponent/Button";

//Renders a Dialog from Material UI
/**
 *
 * title
 * hasCloseBtn: shows the close button
 * hasCancelBtn: shows the cancel button
 * hasConfirmBtn: shows the confirm button
 * onClose: state function that change the state on Close
 * onCancel: state function that change the state on Cancel
 * onConfirm: state function that change the state on Confirm
 * size: xs, sm, md, lg, xl
 * width: special width, overloaps the size
 * isLoading: if the confirm action has a loading state
 * cancelTextBtn: text to display for the cancel button
 * confirmTextBtn: text to display for confirm button
 */
const DialogCard = ({
  title,
  hasCloseBtn,
  hasCancelBtn,
  hasConfirmBtn,
  onClose,
  onCancel,
  onConfirm,
  size,
  isLoading,
  children,
  cancelTextBtn,
  confirmTextBtn,
  fullscreen,
  disableEscapeKeyDown,
  disableOnClickBackdrop,
  disableEnforceFocus
}: $TSFixMe) => {
  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          if (!disableOnClickBackdrop) {
            onClose();
          }
        } else {
          onClose();
        }
      }}
      maxWidth={size}
      fullWidth={true}
      fullScreen={fullscreen}
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'JSXElemen... Remove this comment to see the full error message
      PaperComponent={'div'}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      scroll='paper'
      PaperProps={{
        style: {
          overflowY: 'unset'
        }
      }}
    >
      <div className="flex flex-col overflow-hidden items-start justify-start bg-white-main rounded-xl pr-4 pl-4 pt-2 pb-2 shadow-lg gap-4 h-full">
        {/* Title */}
        <Stack width='100%'>
          <span className="text-sm text-gray-strong font-bold tracking-wider p-2 text-center">{title}</span>
          {/* Close Dialog Button */}
          {hasCloseBtn && (
            <IconButton
              aria-label="close"
              disableRipple
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 4,
                top: 4,
                '&:hover > svg': {
                  fill: 'var(--gray-strong)',
                  transition: '0.2s ease'
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="var(--gray-main)">
                <path d="m12 13.4-4.9 4.9q-.275.275-.7.275-.425 0-.7-.275-.275-.275-.275-.7 0-.425.275-.7l4.9-4.9-4.9-4.9q-.275-.275-.275-.7 0-.425.275-.7.275-.275.7-.275.425 0 .7.275l4.9 4.9 4.9-4.9q.275-.275.7-.275.425 0 .7.275.275.275.275.7 0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7 0 .425-.275.7-.275.275-.7.275-.425 0-.7-.275Z" />
              </svg>
            </IconButton>
          )}
        </Stack>
        {/* Content */}
        <Stack height='100%' width='100%' overflow='auto' gap={2}>
          {children}
        </Stack>
        {/* Buttons */}
        <Stack alignItems='center' width='100%' direction={{ xs: 'column', sm: 'row' }} gap={2} justifyContent='center' className={'buttons'} padding={'0.5em'}>
          {hasCancelBtn && <Button variant='outline' disabled={isLoading} onClick={onCancel} name={cancelTextBtn ? cancelTextBtn : 'Cancelar'} />}
          {hasConfirmBtn && <Button disabled={isLoading} onClick={onConfirm} isLoadingRequest={isLoading} name={confirmTextBtn ? confirmTextBtn : 'Confirmar'} />}
        </Stack>
      </div>
    </Dialog>
  );
};

export default DialogCard;
