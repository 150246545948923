import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ObjectId } from 'bson';
import { useMediaQuery, useTheme } from '@mui/material';
import { useProfileProvider } from '../../store/ProfileProvider';
import NavProfileDesktop from './NavProfileDesktop';
import NavProfileMovil from './NavProfileMovil';

import styles from './styles/Navbar.module.css';

//Navbar para dispositivos mayores a 768px de resolucion.
const Navbar = ({
    session
}: $TSFixMe) => {
    const theme = useTheme();
    const router = useRouter(); //Accede al objeto router de la app.
    // const { store, dataUser,dataUserLoading,dataUserError } = useProfileProvider();
    const isMovil = useMediaQuery(theme.breakpoints.down('md'));

    const onClickStartProject = () => {
        // localStorage.setItem('phase', '');
        router.push({ pathname: '/my_projects/new_project/[id]' }, { pathname: `/my_projects/new_project/${new ObjectId().toString()}` });
    }

    if (isMovil) {
        return <Movil router={router} />;
    }

    return <Desktop session={session} router={router} onClickStartProject={onClickStartProject} />;
}

const Movil = ({
    router
}: $TSFixMe) => {
    return (
        <div className={styles.header_info_movil}>
            <div className={styles.header_logo} onClick={() => router.push('/')}>
                <Image src='/R3D.com.mx.svg' alt='logo-r3d' width={200} height={35} priority={true} />
                {/* {session ? <Image src='/R3D.com.mx.svg' alt='logo-r3d' width={200} height={35} priority={true} />
                    : <Image src='/IMAGOTIPO-blanco.svg' alt='logo-r3d' width={200} height={35} priority={true} />
                } */}
            </div>
            <NavProfileMovil />
        </div>
    )
}

const Desktop = ({
    session,
    router,
    onClickStartProject
}: $TSFixMe) => {
    // @ts-expect-error TS(2339): Property 'store' does not exist on type 'unknown'.
    const { store, dataUser, dataUserLoading, dataUserError } = useProfileProvider();
    return (
        <div className={styles.header_info_desktop}>
            <div className={styles.header_logo} onClick={() => router.push('/')}>
                {session ?
                    <Image src='/R3D.com.mx.svg' alt='logo-r3d'
                        width={200}
                        height={35}
                        priority={true}
                    />
                    : <Image src='/IMAGOTIPO-blanco.svg'
                        alt='logo-r3d'
                        width={200}
                        height={35}
                        priority={true}
                    />
                }
            </div>
            <nav className={styles.navbar}>
                {
                    router.pathname !== '/my_projects/new_project/[id]' && (store.profile === 'MIR3D' || !session) && <button onClick={onClickStartProject} className={styles.navbar_link} id={"btn_start_project_header"}>Iniciar proyecto</button>
                }
                {
                    session &&
                    <>
                        {
                            store.profile === 'FABRICANTE' && <button onClick={() => router.push({ pathname: '/explore' })} className={styles.navbar_link} id={"btn_explore_header"}>Explorar</button>
                        }
                        {
                            store.profile === 'MIR3D' && <Link href='/my_projects'><a className={styles.navbar_link}>Mis proyectos</a></Link>
                        }
                        {
                            store.profile === 'FABRICANTE' && <Link href='/orders'><a className={styles.navbar_link}>Mis proyectos</a></Link>
                        }
                    </>
                }
            </nav>
            <div className={styles.header_inf_signContainer}>
                {
                    session ?
                        <div className={styles.signInOptionsDesktop}>
                            {
                                !dataUserLoading && !dataUserError && dataUser &&
                                <NavProfileDesktop username={dataUser.profile?.profileName || dataUser.firstname} />
                            }

                        </div>
                        : <div className={styles.signButtons}>
                            <button onClick={() => router.push('/sign_in')} className={styles.signButtons_signIn} id={"btn_signin_header"}>INICIAR SESIÓN</button>
                            <button onClick={() => router.push("/sign_up")} className={styles.signButtons_signUp} id={"btn_signup_header"}>REGISTRO</button>
                        </div>
                }
            </div>
        </div>
    )
}

export default Navbar;