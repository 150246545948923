import { gql } from '@apollo/client';

export const REGISTER_USER = gql`
  mutation registerUser($registerUserData: RegisterUserInput!) {
    registerUser(data: $registerUserData) {
      firstname
      lastname
      email
      password
    }
  }
`;

export const SEND_REGISTER_CONFIRMATION_EMAIL = gql`
  mutation sendRegisterConfirmationEmail($email: String!) {
    sendRegisterConfirmationEmail(email: $email) {
      status
      message
      receiver
      token
    }
  }
`;

export const SEND_RESET_USER_PASSWORD_EMAIL = gql`
  mutation sendResetUserPasswordEmail($email: String!) {
    sendResetUserPasswordEmail(email: $email) {
      status
      message
      receiver
      token
    }
  }
`;

export const SEND_CHANGE_USER_PASSWORD_EMAIL = gql`
  mutation sendChangeUserPasswordEmail($email: String!) {
    sendChangeUserPasswordEmail(email: $email) {
      status
      message
      receiver
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetUserPassword($password: String!, $token: String!) {
    resetUserPassword(password: $password, token: $token)
  }
`;

export const CREATE_USER_PROFILE = gql`
  mutation createUserProfile($data: createUserProfileInput!, $userId: ID!) {
    createUserProfile(data: $data, userId: $userId) {
      firstname
      lastname
      email
      isConfirmed
      registeredProfile
      mainProfile
      profiles
      profile {
        company
        profileName
        profileImage {
          src 
          width
          height
          type
          blurDataURL
        }
        occupation
        location {
          postalCode
          state
          municipality
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_IMAGE = gql`
  mutation updateUserProfileImage($imageFile: Upload!, $userId: ID!) {
    updateUserProfileImage(imageFile: $imageFile, userId: $userId) {
      src 
      width
      height
      type
      blurDataURL
    }
  }
`;

export const UPDATE_BACKGROUND_IMAGE = gql`
  mutation updateUserBackgroundImage($imageFile: Upload!, $userId: ID!) {
    updateUserBackgroundImage(imageFile: $imageFile, userId: $userId) {
      src 
      width
      height
      type
      blurDataURL
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($data: UpdateUserProfileInput!, $userId: ID!) {
    updateUserProfile(data: $data, userId: $userId) {
      profileName
      profileImage {
        blurDataURL
      }
      profileBackgroundImage {
        blurDataURL
      }
      description
      occupation
      location {
        postalCode
        state
        municipality
      }
      company
      contactLinks {
        facebook
        instagram
        twitter
        web
        linkedin
      }
    }
  }
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation updateUserAccount($data: UserAccountInput!, $userId: ID!) {
    updateUserAccount(data: $data, userId: $userId) {
      firstname
      lastname
      rfc
      phoneNumber
      cellphoneNumber
    }
  }
`;

export const CREATE_USER_ADDRESS = gql`
  mutation createUserAddress($data: UserAddressInput!, $userId: ID!) {
    createUserAddress(data: $data, userId: $userId) {
      street
      suburb
      state
      fullname
      municipality
      outdoorNumber
      indoorNumber
      postalCode
      reference
      contactNumber
    }
  }
`;

export const UPDATE_USER_ADDRESS = gql`
  mutation updateUserAddress($data: UserAddressInput!, $where: WhereUserAddressInput!) {
    updateUserAddress(data: $data, where: $where) {
      fullname
      state
      street
      suburb
      municipality
      outdoorNumber
      postalCode
      contactNumber
      reference
      indoorNumber
    }
  }
`;

export const DELETE_USER_ADDRESS = gql`
  mutation deleteUserAddress($where: WhereUserAddressInput!) {
    deleteUserAddress(where: $where) {
      fullname
      state
      street
      suburb
      municipality
      outdoorNumber
      postalCode
      contactNumber
      reference
      indoorNumber
    }
  }
`;

export const UPDATE_USER_PROFILES = gql`
  mutation UpdateUserProfiles($data: UpdateUserProfilesInput!, $where: WhereUserInput!) {
    updateUserProfiles(data: $data, where: $where)
  }
`;

export const UPDATE_USER_TOURS = gql`
  mutation UpdateUserTours($data: UpdateUserToursInput!, $where: WhereUserInput!) {
    updateUserTours(data: $data, where: $where)
  }
`;

export const REGISTER_USER_FROM_BRIEF = gql`
  mutation RegisterFromBrief($data: RegisterUserInput!) {
    registerFromBrief(data: $data) {
      _id
      email
      firstname
      isAdmin
    }
  }
`; 


export const SIGN_IN_USER_WITH_PROVIDER = gql`
  mutation SignInUserWithProvider($data: RegisterUserInput!) {
    signInUserWithProvider(data: $data) {
      _id
      cellphoneNumber
      email
      firstname
      isAdmin
      isConfirmed
      lastname
      mainProfile
      password
      phoneNumber
      profiles
      registeredProfile
    }
  }
`;

export const SIGN_IN_USER_WITH_PROVIDER_FROM_BRIEF = gql`
  mutation SignInUserWithProviderFromBrief($data: RegisterUserInput!) {
    signInUserWithProviderFromBrief(data: $data) {
      _id
      cellphoneNumber
      email
      firstname
      isAdmin
      isConfirmed
      lastname
      mainProfile
      password
      phoneNumber
      profiles
      registeredProfile
    }
  }
`;

export const SIGN_IN_USER = gql`
    mutation signInUser($data: SignInInput!) {
        signInUser(data: $data) {
            _id
            firstname
            lastname
            email
            isConfirmed
            registeredProfile
            mainProfile
            profiles
            isAdmin
        }
    }
`;

export const UPDATE_USER_SESSION = gql`
    mutation UpdateUserSession($token: String!) {
    updateUserSession(token: $token) {
        _id
        firstname
        lastname
        email
        isConfirmed
        registeredProfile
        mainProfile
        profiles
        isAdmin
    }
    }
`;

export const SEND_USER_CONFIRMATION_ACCOUNT_EMAIL = gql`
  mutation SendUserConfirmationAccountEmail($email: String!) {
    sendUserConfirmationAccountEmail(email: $email) {
      status
      message
    }
  }
`;

export const VERIFY_USER_PASSWORD = gql`
mutation VerifyUserPassword($password: String!) {
  verifyUserPassword(password: $password)
}
`;