import { Fragment } from "react";
import styles from '../NavbarComponent/styles/NavProfile.module.css'
import Link from "next/link";

const SignInUserManufacturer = () => {
    return (
        <Fragment>
            <li className={styles.menu_item}>
                <Link href='/my_profile'><a>Mi cuenta</a></Link>
            </li>
            <li className={styles.menu_item}>
                <Link href='/manufacturer/factory'><a>Mi fábrica</a></Link>
            </li>
            <li className={styles.menu_item}>
                <Link href='/manufacturer/factory?section=myProducts'><a>Mis productos</a></Link>
            </li>
        </Fragment>
    )
}

export default SignInUserManufacturer;