import React, { useRef, useState } from 'react';
import Image from 'next/image';
import useHandleShowMenu from '../SignInOptionsDesktopComponent/hooks/useHandleShowMenu';
import { useProfileProvider } from '../../store/ProfileProvider';
import SwitchProfile from '../SwitchProfileComponent/SwitchProfile';
import NavProfileItems from './NavProfileItems';

import styles from './styles/NavProfile.module.css';

const NavProfileMovil = () => {
    // @ts-expect-error TS(2339): Property 'store' does not exist on type 'unknown'.
    const { store } = useProfileProvider();
    const userContainer = useRef(null);
    const userMenu = useRef(null);
    const [isMenuOpen, handleOpenMenu] = useHandleShowMenu(userContainer.current, userMenu.current);
    const [displayProfileSwitcher, setDisplayProfileSwitcher] = useState(false);

    let imgProfile = null;

    switch (store.profile) {
        case 'MIR3D':
            imgProfile = '/Cambiar-perfil-miR3D.svg';
            break;
        case 'CREADOR':
            imgProfile = '/Cambiar-perfil-CREAR.svg';
            break;
        case 'FABRICANTE':
            imgProfile = '/Cambiar-perfil-fab.svg';
            break;
        default:
            imgProfile = '/ISOTIPO-blanco.svg';
            break;
    }

    return (
        <div ref={userContainer} className={styles.user_info_container}>
            {
                displayProfileSwitcher &&
                <SwitchProfile onClose={() => setDisplayProfileSwitcher(false)} />
            }
            <Image
                id={'menu_movil'}
                className={styles.profile_image}
                // @ts-expect-error TS(2349): This expression is not callable.
                onClick={() => { handleOpenMenu(userMenu.current) }}
                src={imgProfile}
                alt='isotipo-r3d'
                width={40}
                height={40}
                priority={true}
            />
            <NavProfileItems
                userMenuRef={userMenu}
                isMenuOpen={isMenuOpen}
                onDisplayProfileSwitcher={() => setDisplayProfileSwitcher(true)}
            />
        </div>
    )
}

export default NavProfileMovil;