import React, { useEffect, useRef } from 'react';
import Image from 'next/image';
import styles from './styles/ProfilesDesktop.module.css';
import { Card } from '../ui/card'

//hooks

//Componente para mostrar los perfiles disponibles a seleccionar para desktop.
const ProfilesDesktop = ({
    registerProfile,
    getProfileSelected,
    slideSections,
    availableProfiles,
    handleOnClickProfile
}: $TSFixMe) => {
    const itemsRef = useRef([]);

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, availableProfiles.length);
    }, [availableProfiles])

    /*Funcion que se ejecuta cuando el usuario selecciona un tipo de perfil, se le pasa por parametro el perfil.
    Se ejecuta dos funciones, las cuales son recibidas como props del componente.*/
    const handleOnClick = (profile: $TSFixMe) => {
        if (registerProfile) {
            slideSections('uploadImage'); //Mueve a la seccion para cargar la imagen de perfil.
            getProfileSelected(profile); //Obtiene el perfil seleccionado.
        } else {
            handleOnClickProfile(profile);
        }
    }

    return (
        <div className="flex flex-row justify-center items-start w-full overflow-auto h-[450px] p-4 lg:items-center">
            <div className="flex flex-col gap-6 sm:flex-col md:flex-col lg:flex-row">
                {
                    availableProfiles && availableProfiles.length >= 1 ?
                        availableProfiles.map((profile: $TSFixMe, index: $TSFixMe) => {
                            switch (profile) {
                                case 'MIR3D':
                                    return <Card key={index} className="max-w-96 p-4 gap-4 items-center cursor-pointer w-full flex flex-col bg-white-main" onClick={() => handleOnClick(profile)}>
                                        <Image src='/seleccionar-mir3d.svg' alt='isotipo r3d logo' width={60} height={60} priority={true} />
                                        <p className="text-base font-bold">
                                            MI R3D
                                        </p>
                                        <p className="text-xs tracking-wider text-gray-strong">
                                            Al seleccionar este perfil,
                                            podrás <span className={styles.profilesDesktop_textFocus}>Iniciar proyectos</span>.
                                            <span className={styles.profilesDesktop_textFocus}> R3D</span> te conecta con <span className={styles.profilesDesktop_textFocus}>Fabricantes</span> <span className={styles.profilesDesktop_textFocus_important}>para fabricar productos bajo demanda.</span>
                                        </p>
                                    </Card>;
                                case 'FABRICANTE':
                                    return <Card key={index} className="max-w-96 p-4 gap-4 items-center cursor-pointer w-full flex flex-col bg-white-main" onClick={() => handleOnClick(profile)}>
                                        <Image src='/seleccionar-fabricar.svg' alt='isotipo r3d logo' width={60} height={60} priority={true} />
                                        <p className="text-base font-bold">
                                            FABRICANTE
                                        </p>
                                        <p className="text-xs tracking-wider text-gray-strong">
                                            Al seleccionar este perfil,
                                            podrás <span className={styles.profilesDesktop_textFocus}>Fabricar proyectos</span>.
                                            <span className={styles.profilesDesktop_textFocus}> R3D</span> te conecta con <span className={styles.profilesDesktop_textFocus}>Clientes</span> <span className={styles.profilesDesktop_textFocus_important}>para fabricar productos bajo demanda.</span>
                                        </p>
                                    </Card>
                                default:
                                    return null;
                            }
                        }) : null
                }
            </div >
        </div>
    );
}

export default ProfilesDesktop;